<template>
  <ion-page>
    <Navbar />
    <ion-content :fullscreen="true">
      <EmergencyContainer />
    </ion-content>
  </ion-page>
</template>
<script>
import Navbar from '@/components/partials/Navbar.vue';
import { IonPage, IonContent, toastController } from '@ionic/vue';
import EmergencyContainer from '@/components/EmergencyContainer.vue';
import store from '../store/index';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';

export default {
  name: 'emergency',
  components: {
    Navbar,
    IonPage,
    IonContent,
    EmergencyContainer,
  },
  async created () {
    let parsed_user = {};
    let bytes = "";
    let decryptedData = "";

    let cipherData = VueCookies.get('misc');

    if (cipherData === null  || cipherData === undefined || cipherData === '') {
      window.location.href = "/login";
    } else {
      bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
      decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (decryptedData) { 
        let decode = JSON.parse(decryptedData);
        let user = decode.user;

        //Check if user exists
        if (user) {
          if (user.employee_no === null && user.employee_no === "" && user.employee_no === undefined) {
            this.$router.push('/onboard')
          } else {
            return;
          }
        } else {
          //Run verify auth if data does not exist
          await store.dispatch('login/verifyAuth').then(() => {
            parsed_user = JSON.parse(store.getters['login/currUser']);
            return;
          }).catch(err => {
            if (err.status == 401) {
              localStorage.setItem('is_session_expired', true);
              localStorage.removeItem("is_consent_agreed");
              VueCookies.remove("misc");
              VueCookies.remove("misc-hs");
              window.location.href = '/login';
            }
          });
          
          if (parsed_user.employee_no === null && parsed_user.employee_no === "" && parsed_user.employee_no === undefined) {
            this.$router.push('/onboard')
          } else {
            return;
          }
        }
      } else {
        localStorage.setItem('is_session_expired', true);
        localStorage.removeItem("is_consent_agreed");
        VueCookies.remove("misc");
        VueCookies.remove("misc-hs");
        window.location.href = '/login';
      }
    }
  },
  methods:{
    async openToast(msg, type) {
			const toast = await toastController
				.create({
					message: msg,
					animated: true,
					duration: 2000,
					color: type,
          cssClass: 'toast-custom',
					end: true
				})
			return toast.present();
		},
  }
};
</script>
<style scoped>

</style>