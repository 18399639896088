<template>
  <ion-content>
    <div class="emergency-header">
      <h1>Emergency Contacts</h1>
    </div>
    <div class="emergency-content">
      <ion-card class="emergency-card-container">
        <ion-row class="emergency-card-content">
          <ion-col class="emergency-card-header">
            <ion-card-header class="emergency-card-title">
              <h3>Department of Health (DOH) COVID-19 Emergency Hotline</h3>
            </ion-card-header>
            <ion-card-content class="emergency-card-number">
              <p>02-894-COVID (02-894-26843)</p>
            </ion-card-content>
          </ion-col>
          <ion-col class="emergency-card-action">
            <ion-button class="emergency-card-button" @click="executeCall('+63289426843')" href="tel:+63289426843">
              <ion-icon slot="start"  name="call"></ion-icon>
              Call
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
      <ion-card class="emergency-card-container">
        <ion-row class="emergency-card-content">
          <ion-col class="emergency-card-header">
            <ion-card-header class="emergency-card-title">
              <h3>Research Institute of Tropical Medicine (RITM)</h3>
            </ion-card-header>
            <ion-card-content class="emergency-card-number">
              <p>(02) 8807-2631</p>
            </ion-card-content>
          </ion-col>
          <ion-col class="emergency-card-action">
            <ion-button class="emergency-card-button" @click="executeCall('+63288072631')" href="tel:+63288072631">
              <ion-icon slot="start"  name="call"></ion-icon>
              Call
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
      <div class="emergency-header">
        <h3>PETNET Inc.:</h3>
      </div>
      <ion-card class="emergency-card-container">
        <ion-row class="emergency-card-content">
          <ion-col class="emergency-card-header">
            <ion-card-header class="emergency-card-title">
              <h3>PETNET HR Department</h3>
            </ion-card-header>
            <ion-card-content class="emergency-card-number">
              <p>0915 675 6816</p>
            </ion-card-content>
          </ion-col>
          <ion-col class="emergency-card-action">
            <ion-button class="emergency-card-button" @click="executeCall('+639156756816')" href="tel:+639156756816">
              <ion-icon slot="start" name="call"></ion-icon>
              Call
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
      <div class="emergency-header">
        <h5>Send via email:</h5>
      </div>
      <ion-card class="emergency-card-container">
        <ion-row class="emergency-card-content">
          <ion-col class="emergency-card-header">
            <ion-card-header class="emergency-card-title">
              <h3>Beata Purificacion</h3>
            </ion-card-header>
            <ion-card-content class="emergency-card-number">
              <p>beata.purificacion@petnet.com.ph</p>
            </ion-card-content>
          </ion-col>
          <ion-col class="emergency-card-action">
            <ion-button class="emergency-card-button" target="_blank" href="mailto:beata.purificacion@petnet.com.ph">
              <ion-icon slot="start" name="mail-outline"></ion-icon>
              Send Email
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
      <ion-card class="emergency-card-container">
        <ion-row class="emergency-card-content">
          <ion-col class="emergency-card-header">
            <ion-card-header class="emergency-card-title">
              <h3>Rachelle Espella</h3>
            </ion-card-header>
            <ion-card-content class="emergency-card-number">
              <p>rachelle.espela@petnet.com.ph</p>
            </ion-card-content>
          </ion-col>
          <ion-col class="emergency-card-action">
            <ion-button class="emergency-card-button" target="_blank" href="mailto:rachelle.espela@petnet.com.ph">
              <ion-icon slot="start" name="mail-outline"></ion-icon>
              Send Email
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
    </div>
  </ion-content>
</template>
<script>
import { IonCardHeader, IonCardContent, IonCol, IonRow, IonIcon, IonButton, IonCard, IonContent } from '@ionic/vue';
import { CallNumber } from "@ionic-native/call-number";

export default {
  name: 'EmergencyContainer',
  components: { IonCardHeader, IonCardContent, IonCol, IonRow, IonIcon, IonButton, IonCard, IonContent },
  methods: {
    executeCall(number) {
      CallNumber.callNumber(number)
        .then((res) => console.log("Launched dialer!", res))
        .catch((err) => console.log("Error launching dialer", err));
    }
  }  
};
</script>
<style scoped>
  .emergency-header {
    font-family: "Roboto Bold";
    padding: 5px 15px;
  }

  .emergency-card-title h3 {
    margin: 0;
    font-family: "Roboto Bold";
    font-size: 18px;
  }

  .emergency-card-number p {
    margin: 0;
    font-family: "Roboto Medium";
    font-size: 16px;
  }

  .emergency-card-action {
    text-align: right;
  }

  .emergency-card-button {
    margin: 15px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .emergency-card-content {
    display: block;
  }

  @media only screen and (min-width: 768px) {
    .emergency-card-content {
      display: flex;
    }

    .emergency-card-button {
      margin-left: 15px;
      margin-right: 15px;
    }

    .emergency-card-title h3 {
      font-size: 20px;
    }

    .emergency-card-number p {
      font-size: 18px;
    }
  } 

  /* .history-icon {
    margin-right: 10px;
  }

  .history-status {
    font-family: "Roboto Regular";
    font-weight: bold;
  } */
</style>